import { graphql } from 'gatsby'
import * as React from 'react'
import Enquiry from '../components/enquiry'
import Introduction from '../components/introduction'
import Layout from '../components/layout'
import LogoCarousel from '../components/logoCarousel'
import Newsletter from '../components/newsletter'
import ParallaxBanner from '../components/parallaxBanner'
import Perspectiv from '../components/perspectiv'
import ProjectGrid from '../components/projectGrid'
import ScrollingLogo from '../components/scrollingLogo'
import SEO from '../components/seo'
import Services from '../components/services'
import Spacer from '../components/spacer'

class IndexPage extends React.Component {
	render() {
		var projects = this.props.data?.allDatoCmsHomepage?.nodes[0]?.projects
		var data = this.props.data?.allDatoCmsHomepage?.nodes[0]
		const count = this.props.data?.projectsNumber
		var articles = this.props.data?.articles
		return (
			<Layout home={true} header='fixed'>
				<SEO title='Homepage' />
				<ScrollingLogo />
				<Newsletter />
				<ParallaxBanner home={true} image={data?.heroImage} text={data?.heroText} gallery={data?.heroGallery} />
				<div data-scroll className='bg-black'>
					<Introduction title={data?.introductionText} blocks={data?.introductionBlocks} />
					<LogoCarousel title={data?.logoTitle} logos={data?.logos} />
					<ProjectGrid count={count?.totalCount} title={data?.projectsTitle} projects={projects} />
					<Spacer />
					<Spacer className='m-hide' />
					<Enquiry title={data?.contactTitle} titleTwo={data?.contactTitleTwo} subtitle={data?.contactSubtitle} subtitleTwo={data?.contactSubtitleTwo} />
					<Spacer />
					<Spacer className='m-hide' />
					<Services title={data?.serviceTitle} services={data?.services} />
					<Spacer />
					<Spacer />
					<Perspectiv subtitle={data?.perspectivSubtitle} articles={articles} />
					<div className='pl40 pr40 m-pl20 m-pr20'>
						<div className='bb1' />
					</div>
				</div>
			</Layout>
		)
	}
}

export default IndexPage

export const query = graphql`
	query HomeQuery {
		allDatoCmsHomepage {
			nodes {
				heroText
				serviceTitle
				projectsTitle
				introductionText
				ctaTitle
				ctaSubtitle
				services {
					copyline
					title
				}
				logoTitle
				logos {
					originalId
					gatsbyImageData(imgixParams: {q: 100})
				}
				introductionBlocks {
					image {
						gatsbyImageData
					}
					videoUrl
					title
				}
				heroImage {
					gatsbyImageData
				}
				heroGallery {
					gatsbyImageData
				}
				ctaImage {
					gatsbyImageData
				}
				contactTitle
				contactSubtitle
				contactTitleTwo
				contactSubtitleTwo
				perspectivSubtitle
				projects {
					name
					projectStatus {
						name
					}
					heroCaption
					heroImage {
						gatsbyImageData
					}
					slug
				}
			}
		}
		articles: allDatoCmsArticle(sort: {fields: meta___createdAt, order: DESC}) {
			nodes {
				title
				slug
				readTime
				category {
					title
				}
				image {
					gatsbyImageData
				}
				meta {
					createdAt(formatString: "DD/MM/YY")
				}
			}
		}
		projectsNumber: allDatoCmsProject {
			totalCount
		}
	}
`
